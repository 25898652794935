<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="90px">
        <el-form-item label="渠道名称">
          <el-input v-model="searchData.channelName" size="small" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="姓名">
          <el-input v-model="searchData.memberName" size="small" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="searchData.mobile" size="small" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="身份证号">
          <el-input v-model="searchData.identityNo" size="small" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="创建时间">
          <el-date-picker
            style="width:250px;"
            size="small"
            v-model="searchData.timeSlot"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label=" ">
          <el-button type="primary" size="small" icon="el-icon-search" @click="getList()">搜索</el-button>
          <el-button type="info" size="small" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-divider></el-divider>
    <tp-table :tableData="list" :columns="columns" :totalNum="total" :current-page.sync="currentPage" :pageSize.sync="pageSize" />
  </div>
</template>

<script>
import { getListAPI, addRosterAPI } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'
const columns = [
  {
    label: 'ID',
    prop: 'id',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['id']}</p>
    }
  },
  {
    label: '昵称',
    prop: 'nickName',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['nickName']}</p>
    }
  },
  {
    label: '姓名',
    prop: 'memberName',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['memberName']}</p>
    }
  },
  {
    label: '手机号',
    prop: 'mobile',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['mobile']}</p>
    }
  },
  {
    label: '身份证号',
    prop: 'identityNo',
    minWidth: '180',
    customRender(h, row) {
      return <p>{row['identityNo']}</p>
    }
  },
  {
    label: '性别',
    prop: 'genderName',
    minWidth: '80',
    customRender(h, row) {
      return <p>{row['genderName']}</p>
    }
  },
  {
    label: '出生日期',
    prop: 'birthDay',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['birthDay']}</p>
    }
  },
  {
    label: '状态',
    prop: 'status',
    minWidth: '100',
    customRender(h, row) {
      return (
        <div>
          <el-tag v-show={row['status'] == 1} type="success" size="small">
            正常
          </el-tag>
          <el-tag v-show={row['status'] == 2} type="danger" size="small">
            注销
          </el-tag>
        </div>
      )
    }
  },
  {
    label: '渠道归属',
    prop: 'channel',
    minWidth: '130',
    customRender(h, row) {
      return <p>{row['channel']?.['channelName']}</p>
    }
  },
  {
    label: '创建时间',
    prop: 'createTime',
    minWidth: '180',
    customRender(h, row) {
      return <p>{row['createTime']}</p>
    }
  },
  {
    label: '操作',
    width: '150',
    fixed: 'right',
    customRender(h, row) {
      return (
        <div>
          <el-link
            disabled={!this.isShowBtn(this.AUTH_BTN.member_list_add_black)}
            underline={false}
            type="primary"
            onClick={() => this.addRoster(row['id'], 2)}
          >
            <span>加入黑名单</span>
          </el-link>
          <el-link
            disabled={!this.isShowBtn(this.AUTH_BTN.member_list_add_white)}
            underline={false}
            type="primary"
            onClick={() => this.addRoster(row['id'], 1)}
          >
            <span>加入白名单</span>
          </el-link>
        </div>
      )
    }
  }
]
export default {
  name: 'List',
  mixins: [authBtnMixin],
  data() {
    return {
      columns,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      list: [],
      searchData: {
        channelName: '',
        memberName: '',
        mobile: '',
        identityNo: '',
        timeSlot: [],
        leftCreateDate: '',
        rightCreateDate: ''
      }
    }
  },
  provide() {
    return {
      context: this
    }
  },
  activated() {
    this.getList()
  },
  watch: {
    currentPage() {
      this.getList()
    },
    pageSize() {
      this.getList()
    }
  },
  mounted() {
    this.getList()
  },

  methods: {
    async getList() {
      const { channelName, memberName, mobile, identityNo, timeSlot } = this.searchData
      let params = { page: this.currentPage, pageSize: this.pageSize, channelName, memberName, mobile, identityNo }

      if (timeSlot && timeSlot.length > 0) {
        params.leftCreateDate = timeSlot[0] || ''
        params.rightCreateDate = timeSlot[1] || ''
      }
      const res = await getListAPI(params)
      this.list = res.list || []
      this.total = res.total || 0
    },
    // 加入黑/白名单
    addRoster(id, riskLevel) {
      let data = { id, riskLevel }
      console.log(riskLevel)
      this.$confirm(`是否将该用户加入${riskLevel == 2 ? '黑' : '白'}名单, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          addRosterAPI(data).then(() => {
            this.getList()
            this.$message.success('操作成功')
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .header-search {
    .el-form {
      .el-form-item {
        margin-right: 35px;
        .el-input {
          width: 250px;
        }
        .el-date-editor {
          .el-range-separator {
            padding: 0;
          }
          .el-range-input {
            width: 35%;
          }
        }
      }
    }
  }
  .el-date-editor .el-range-separator {
    padding: 0;
  }
  .el-divider--horizontal {
    margin: 0 0 20px;
  }
  .tp-table {
    .el-table__fixed-right {
      height: 100% !important;
    }
  }
}
</style>
