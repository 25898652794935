import { http } from '@/http/axios.js'
// 列表
export function getListAPI(params) {
  return http({
    url: '/admin/member/page',
    method: 'get',
    params
  })
}
// 加入黑名单
export function addRosterAPI(data) {
  return http({
    url: '/admin/member/addRoster',
    method: 'post',
    data
  })
}
